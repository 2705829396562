<template>
  <div :class="$style.helpWidgetPosition">
    <div v-show="showMenu" :class="$style.helpWidgetFlyOutMenu">
      <a :class="$style.helpWidgetLink" target="_blank" href="https://beapplied.notion.site/b68745c610ab4e0ba96dcddd105f51a9?v=cd7dd5d85d4647fb93dfa5e17adfd146">
        <span :class="$style.helpWidgetMenuOption">
          <p :class="$style.helpWidgetMenuText">
            Latest product updates
          </p>
        </span>
      </a>
      <a :class="$style.helpWidgetLink" target="_blank" href="https://appliedhelp.zendesk.com/hc/en-us">
        <span :class="$style.helpWidgetMenuOption">
          <p :class="$style.helpWidgetMenuText">
            FAQs and Help Centre
          </p>
        </span>
      </a>
      <a
        :class="$style.helpWidgetLink"
        target="_blank"
        href="https://appliedhelp.zendesk.com/hc/en-us/requests/new"
      >
        <span :class="$style.helpWidgetMenuOption">
          <p :class="$style.helpWidgetMenuText">Technical question? Contact Applied</p>
        </span>
      </a>
    </div>
    <div
      :class="[$style.helpWidgetIconWrapper, $style.helpWidgetTooltip]"
      v-click-outside="hideMenu"
      @click="toggleMenu()"
    >
      <FontAwesomeIcon :icon="helpIcon" :class="$style.helpWidgetIcon" />
      <span v-if="!showMenu" :class="$style.helpWidgetTooltiptext"
        >Help and Resources</span
      >
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "HelpWidget",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    helpIcon() {
      return faQuestionCircle;
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style module>
a.helpWidgetLink:link {
  text-decoration: none;
}

a.helpWidgetLink:visited {
  text-decoration: none;
}

a.helpWidgetLink:active {
  text-decoration: none;
}

.helpWidgetFlyOutMenu {
  justify-content: center;
  border-radius: 4px;
  background: #5e687c;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: -82px;
  left: 110%;
  bottom: 24px;
}

.helpWidgetMenuOption {
  padding: 10px 20px;
  white-space: nowrap;
  display: block;
}

.helpWidgetMenuOption:hover {
  background: #002b49;
}

.helpWidgetMenuText {
  margin: 0;
  color: white;
  font-size: 14px;
}

.helpWidgetTooltip {
  position: relative;
  display: inline-block;
}

.helpWidgetTooltip:hover {
  cursor: pointer;
}

.helpWidgetTooltip .helpWidgetTooltiptext {
  visibility: hidden;
  background: #DCDFE4;
  font-size: 14px;
  color: #111723;
  text-align: center;
  border-radius: 4px;
  padding: 10px 20px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 110%;
}

.helpWidgetTooltip:hover .helpWidgetTooltiptext {
  visibility: visible;
}

.helpWidgetPosition {
  position: fixed;
  bottom: 0;
  left: 0;
  font-family: "Maven Pro", sans-serif;
  text-decoration: none;
  z-index: 10;
}

.helpWidgetIconWrapper {
  margin: 0  0 15px 15px;
  background: #8d96a9;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.helpWidgetIconWrapper:hover{
  color: #F7F8FA
}
.helpWidgetIcon {
  font-size: 50px;
}
</style>
