import Vue from 'vue'
import HelpWidget from './HelpWidget.vue'
import 'document-register-element/build/document-register-element'

import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false

Vue.use(vueCustomElement)


Vue.customElement('vue-widget', HelpWidget)
